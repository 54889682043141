
const Colors = {
    "Newbie": "gray",
    "Pupil": "green",
    "Expert": "blue",
    "Candidate Master": "purple",
    "Master": "orange",
    "Grandmaster": "red" 
};

export default Colors;